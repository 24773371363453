import React from 'react';
import { cva } from 'class-variance-authority';
import { cn } from '@/lib/utils';
import PropTypes from 'prop-types';

import { Slot } from '@radix-ui/react-slot';
import Icon from '@/components/atoms/icon';

const buttonVariants = cva(
	'inline-flex items-center justify-center rounded-md text-base font-bold transition-all focus-visible:outline focus-visible:outline-4 focus-visible:outline-notificationsWarning disabled:pointer-events-none disabled:bg-corporateGrey disabled:text-corporateGreyLight gap-2',
	{
		variants: {
			variant: {
				primary:
					'bg-corporateBlueBright text-corporateBlueBright-foreground hover:outline hover:outline-3 hover:outline-corporateBlueBorderHover hover:bg-corporateLightBlueHover active:outline-0 active:bg-corporateLightBlueTint',

				'primary-white':
					'bg-white text-corporateBlueBright hover:outline hover:outline-3 hover:outline-corporateBlueLight2 active:outline-0 active:bg-formOutline disabled:text-white',

				secondary:
					'border border-2 border-corporateBlueBright text-corporateBlueBright bg-transparent hover:bg-white-opa-40 hover:border-transparent hover:outline hover:outline-3 hover:outline-corporateBlueBorderHover active:outline-0 active:bg-corporateBlueBrighOpa20 disabled:border-corporateGrey disabled:bg-transparent disabled:text-corporateGrey',

				'secondary-white':
					'border border-2 border-white text-white bg-transparent hover:outline hover:outline-3 hover:bg-white-opa-20 hover:outline-corporateBlueLight2  active:outline-0 active:bg-white-opa-20 disabled:border-corporateGrey disabled:bg-transparent disabled:text-corporateGrey',

				icon: 'bg-transparent text-eis-black hover:bg-corporateGreyLight active:bg-corporateGreyLight focus-visible:bg-corporateGreyLight disabled:bg-transparent disabled:text-corporateGrey',

				'circle-icon': 'bg-transparent text-corporateBlueBright hover:bg-eis-light-blue-1 active:bg-formOutline focus-visible:bg-formOutline rounded-full',

				'action-header-back-button':
					'bg-transparent text-common-text-default hover:bg-formOutline active:bg-formOutline focus-visible:bg-formOutline rounded-md capitalize',

				'small-button':
					'border border-formOutline text-eis-dark-grey bg-transparent hover:bg-corporateGreyLight active:bg-corporateGreyLight  disabled:border-corporateGrey disabled:bg-transparent disabled:text-corporateGrey',

				underline:
					'w-max text-corporateBlueBright hover:text-corporateLightBlueHover relative before:bg-corporateBlueBrighOpa20 before:absolute before:h-px before:w-full before:bottom-0 before:left-0 hover:before:opacity-0 before:transition-all before:duration-300 after:bg-corporateBlueBright after:absolute after:h-px after:w-0 after:bottom-0 after:left-0 hover:after:w-full after:transition-all after:duration-300',

				'nav-button':
					'flex items-center justify-center w-12 h-12 border rounded-full border-corporateLightBlueDarkHint hover:border-corporateLightBlueDarkHint hover:bg-corporateLightBlueDarkHint shrink-0',

				'nav-top-button': 'flex items-center justify-center w-12 h-12 border rounded-full border-formOutline hover:bg-corporateGreyLight shrink-0',

				'form-price-toggle-button':
					'flex items-center justify-center w-11 h-11 rounded-full bg-corporateBlueLight1 shrink-0 text-corporateBlueBright hover:text-white hover:bg-corporateBlueBright transition-all',

				// Note: for "calendar" atom
				outline: 'border border-input bg-background hover:bg-accent hover:text-eis-black',

				// Note: for "calendar" atom
				ghost: 'hover:bg-accent hover:text-eis-black',

				'download-outline':
					'group transition-all w-full flex justify-between gap-4 md:gap-8 border border-formOutline bg-white text-eis-black rounded hover:shadow-drop-shadow',

				'icon-and-underline': 'group gap-3',
			},
			size: {
				default: 'h-10 px-4 py-2',
				'small-button': 'h-8 p-2 text-sm leading-eis-18 font-normal',
				'circle-icon': 'h-10 w-10',
				'action-header-back-button': 'pl-1 pr-3 py-1',
				icon: 'h-10 w-10 p-3',
				none: '',
				'download-outline': 'px-5 py-2',
			},
			fullWidth: {
				fullWidth: 'w-full',
			},
			iconAndUnderline: {
				'icon-and-underline':
					'w-max text-eis-black hover:text-corporateLightBlueHover relative before:bg-transparent before:absolute before:h-px before:w-full before:bottom-0 before:left-0 hover:before:opacity-0 before:transition-all before:duration-300 after:bg-corporateBlueBright after:absolute after:h-px after:w-0 after:bottom-0 after:left-0 group-hover:after:w-full after:transition-all after:duration-300 group-hover:text-corporateBlueBright',
			},
		},
	}
);

const Button = React.forwardRef(
	(
		{
			className,
			variant,
			size,
			fullWidth,
			disabled,
			asChild,
			children,
			icon,
			iconRight,
			arrow,
			iconAndUnderline,
			downloadOutLineIcon,
			textClassName,
			arrowClassName,
			iconClassName,
			iconContainerClassName,
			...props
		},
		ref
	) => {
		const Comp = asChild ? Slot : 'button';
		return (
			<Comp className={cn(buttonVariants({ variant, size, fullWidth, className }))} ref={ref} {...props} disabled={disabled}>
				{icon && (
					<Icon
						name={icon}
						className={cn(
							variant === 'small-button' && 'w-eis-18 h-eis-18',
							variant === 'action-header-back-button' && 'w-6 h-6',
							variant === 'icon-and-underline' && 'w-6 h-6 group-hover:text-corporateBlueBright',
							'shrink-0',
							iconClassName && iconClassName
						)}
					/>
				)}

				{variant === 'download-outline' ? (
					<div className="flex items-center gap-4 md:gap-8">
						<Icon name={downloadOutLineIcon} className="shrink-0" />
						<span className="transition-all group-hover:text-corporateBlueBright group-hover:underline">{children}</span>
					</div>
				) : iconAndUnderline === 'icon-and-underline' ? (
					<span className={cn(buttonVariants({ iconAndUnderline }), textClassName && textClassName)}>{children}</span>
				) : (
					children
				)}

				{iconRight && (
					<div className={iconContainerClassName && iconContainerClassName}>
						<Icon
							name={iconRight}
							className={cn(
								variant === 'small-button' && 'w-eis-18 h-eis-18',
								variant === 'action-header-back-button' && 'w-6 h-6',
								variant === 'icon-and-underline' && 'w-6 h-6 group-hover:text-corporateBlueBright',
								'shrink-0',
								iconClassName && iconClassName
							)}
						/>
					</div>
				)}

				{arrow && (
					<div
						className={cn('flex items-center justify-center w-10 h-10 transition-all bg-transparent rounded-full shrink-0', arrowClassName && arrowClassName)}
					>
						<Icon name="chevron-down" className="-rotate-90 text-corporateBlueBright" />
					</div>
				)}
			</Comp>
		);
	}
);
Button.displayName = 'Button';

export { Button, buttonVariants };

Button.propTypes = {
	className: PropTypes.string,
	variant: PropTypes.oneOf([
		'primary',
		'primary-white',
		'secondary',
		'secondary-white',
		'icon',
		'circle-icon',
		'action-header-back-button',
		'small-button',
		'underline',
		'nav-button',
		'nav-top-button',
		'form-price-toggle-button',
		'outline',
		'ghost',
		'download-outline',
		'icon-and-underline',
		'none',
	]).isRequired,
	size: PropTypes.oneOf(['default', 'small-button', 'circle-icon', 'action-header-back-button', 'icon', 'none', 'download-outline']),
	icon: PropTypes.string,
	downloadOutLineIcon: PropTypes.string,
	fullWidth: PropTypes.oneOf(['fullWidth']),
	disabled: PropTypes.bool,
	asChild: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
	arrow: PropTypes.bool,
	iconAndUnderline: PropTypes.oneOf(['icon-and-underline']),
};

Button.defaultProps = {
	className: null,
	variant: null,
	size: 'default',
	icon: null,
	downloadOutLineIcon: null,
	fullWidth: null,
	disabled: false,
	asChild: false,
	children: null,
	arrow: false,
	iconAndUnderline: null,
};
